import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import letter from "./media/letter.png";
import twtLogo from "./media/twitter-icon.png";

import * as IoIcons from "react-icons/io5";

export default function NavbarMobile() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <div>
      <div className="burger-bar">
        <Link to="#" className="menu-hamburger">
          <IoIcons.IoReorderThree onClick={showSidebar} />
        </Link>
      </div>
      <nav
        className={sidebar ? "nav-menu active" : "nav-menu"}
        onClick={showSidebar}
      >
        <div className="navbar-toggle">
          <Link to="#">
            <IoIcons.IoCloseSharp />
          </Link>
        </div>
        <ul>
          <li>
            <NavLink to="/" className="nav-link">
              home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" className="nav-link">
              about
            </NavLink>
          </li>
          <li>
            <NavLink to="/archive" className="nav-link">
              archive
            </NavLink>
          </li>
          <li>
            <a className="nav-link" href="https://www.muse.place/for-the-tl">
              gallery
            </a>
          </li>
          <li>
            <a
              className="nav-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/forthetl"
            >
              <img src={twtLogo} alt="twitter icon" className="twitter"></img>
            </a>
          </li>
          <li>
            <a
              className="nav-link"
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:forthetlart@gmail.com"
            >
              <img src={letter} alt="twitter icon" className="email"></img>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
