import React from "react";
import tlLogo from "./media/nav_logo.png";
import logo from "./media/bubble_logo_thick.png";
import collab from "./media/collab.jpg";

const description =
  "This digital artwork is comprised of layers from 22 artists in our collective, and marks the first official collaboration of For The TL.";

export default function MobileCarousel(props) {
  return (
    <div className="mobile-home-main">
      <img
        src={tlLogo}
        width="140"
        alt="tl icon"
        className="home-mobile-logo"
      ></img>
      <img className="home-full" alt="collab" src={collab}></img>
      <div className="attributions-container">
        <p>
          <i>THE TL IS HERE</i>
        </p>
        <p>{description}</p>
        <ul>
          <li>
            Aleqth <a href="https://twitter.com/aleqth">@aleqth</a>
          </li>
          <li>
            Bobbi Cai <a href="https://twitter.com/bawbicai">@bawbicai</a>
          </li>
          <li>
            Bryan Brinkman{" "}
            <a href="https://twitter.com/bryanbrinkman">@bryanbrinkman</a>
          </li>
          <li>
            Cat Russell{" "}
            <a href="https://twitter.com/Cat_Russell_">@Cat_Russell_</a>
          </li>
          <li>
            Chirs Cadaver{" "}
            <a href="https://twitter.com/shoppinginjail">@shoppinginjail</a>
          </li>
          <li>
            Dela <a href="https://twitter.com/delafilms">@delafilms</a>
          </li>
          <li>
            Eceertrey <a href="https://twitter.com/eceertrey">@eceertrey</a>
          </li>
          <li>
            Emotionull <a href="https://twitter.com/em0tionull">@em0tionull</a>
          </li>
          <li>
            Gisel Florez{" "}
            <a href="https://twitter.com/GiselFlorez">@GiselFlorez</a>
          </li>
          <li>
            Harvmcm <a href="https://twitter.com/harvmcm">@harvmcm</a>
          </li>
          <li>
            Jordan Devant <a href="https://twitter.com/Jrrdan1">@Jrrdan1</a>
          </li>
          <li>
            karan4d <a href="https://twitter.com/karan4d">@karan4d</a>
          </li>
          <li>
            Max Kulchinsky{" "}
            <a href="https://twitter.com/maxkulchinsky">@maxkulchinsky</a>
          </li>
          <li>
            Myriad of?{" "}
            <a href="https://twitter.com/myriadinart">@myriadinart</a>
          </li>
          <li>
            Noah Kocher
            <a href="https://twitter.com/nolankosher">@nolankosher</a>
          </li>
          <li>
            sauce_k{" "}
            <a href="https://twitter.com/L_SAUCEYwashere">@L_SAUCEYwashere</a>
          </li>
          <li>
            Sean Williams{" "}
            <a href="https://twitter.com/iArtSometimes">@iArtSometimes</a>
          </li>
          <li>
            Sophie Sturdevant{" "}
            <a href="https://twitter.com/sophiesaidso">@sophiesaidso</a>
          </li>
          <li>
            Squibs <a href="https://twitter.com/squibs">@squibs</a>
          </li>
          <li>
            Thomaz Aquino <a href="https://twitter.com/thxaquino">@thxaquino</a>
          </li>
          <li>
            Yosnier <a href="https://twitter.com/Yosnier_">@Yosnier_</a>
          </li>
          <li>
            Vinnie Hager{" "}
            <a href="https://twitter.com/vinniehager">@vinniehager</a>
          </li>
        </ul>
        <img width="100" src={logo}></img>
      </div>
    </div>
  );
}
