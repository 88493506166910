import React from "react";
import { NavLink, Link } from "react-router-dom";
import tlLogo from "./media/nav_logo.png";

export default function NavbarDesktop() {
  return (
    <nav className="nav-desktop">
      <ul>
        <li>
          <Link to="/">
            <img
              src={tlLogo}
              width="140"
              alt="tl icon"
              className="nav-link-img"
            ></img>
          </Link>
        </li>
        <li>
          <NavLink to="/about" className="nav-link">
            about
          </NavLink>
        </li>
        <li>
          <NavLink to="/archive" className="nav-link">
            archive
          </NavLink>
        </li>
        <li>
          <a className="nav-link" href="https://www.muse.place/for-the-tl">
            gallery
          </a>
        </li>
      </ul>
    </nav>
  );
}
