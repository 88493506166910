import React, { useState, useMemo, useEffect } from "react";

export default function ArtRecord(props) {
  const [visible, setVisible] = useState(false);

  const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({
      x: null,
      y: null,
    });

    useEffect(() => {
      const updateMousePosition = (ev) => {
        setMousePosition({ x: ev.clientX, y: ev.clientY });
      };

      window.addEventListener("mousemove", updateMousePosition);

      return () => {
        window.removeEventListener("mousemove", updateMousePosition);
      };
    }, []);

    return mousePosition;
  };

  const mousePosition = useMousePosition();

  let xpos = mousePosition.x + 30;
  let ypos = mousePosition.y - 250;

  // console.log(xpos, ypos);

  const imgStyles = useMemo(() => {
    return {
      position: "fixed",
      display: "block",
      zIndex: "1",
      top: ypos,
      left: xpos,
    };
  }, [xpos, ypos]);

  return (
    <div>
      {visible && (
        <div className="hover-archive-img-container" style={imgStyles}>
          {props.img ? (
            <img className="hover-archive-img" src={props.img} alt=""></img>
          ) : (
            <img className="hover-archive-img" src={props.thumb} alt=""></img>
          )}
        </div>
      )}

      <hr className="dashed"></hr>

      <div
        onMouseEnter={() => setVisible(!visible)}
        onMouseLeave={() => setVisible(!visible)}
        className="artwork-record"
      >
        <div className="art-displayed">
          <i>
            <h3 className="blue">{props.title}</h3>
          </i>
          <p>|</p>
          <h3>{props.artist}</h3>
          <p>|</p>
          <h3>{props.medium}</h3>
          <p>|</p>
          <h3>{props.date}</h3>
        </div>

        <div className="art-mouseover">
          <a target="_blank" rel="noopener noreferrer" href={props.link}>
            details
          </a>
          <p>&#8599;</p>
        </div>
      </div>
    </div>
  );
}
