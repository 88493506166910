import React, { useEffect, useState } from "react";
import Carousel from "./Carousel";
import MobileCarousel from "./MobileCarousel";
import Socials from "./Socials";

export default function Home() {
  const [desktop, setDesktop] = useState(true);
  const [artworkData, setArtworkData] = useState();

  // const base_api_url =
  //   "https://api.airtable.com/v0/appniz0EjFonvIM1o/Table%201?api_key=keyTzNFoKWiWzSmPa";

  // let offset1 = "";
  // let offset2 = "";
  // let data1 = [];
  // let data2 = [];

  // useEffect(() => {
  //   let width = window.innerWidth;
  //   if (width > 900) {
  //     setDesktop(true);
  //   } else {
  //     setDesktop(false);
  //   }

  //   fetch(base_api_url)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       offset1 = data.offset;
  //       data1 = data;

  //       fetch(`${base_api_url}&offset=${offset1}`)
  //         .then((res) => res.json())
  //         .then((data) => {
  //           offset2 = data.offset;
  //           data2 = data;

  //           fetch(`${base_api_url}&offset=${offset2}`)
  //             .then((res) => res.json())
  //             .then((data) => {
  //               setArtworkData([
  //                 ...data1.records,
  //                 ...data2.records,
  //                 ...data.records,
  //               ]);
  //             })
  //             .catch((error) => {
  //               console.log(error);
  //             });
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  // console.log(artworkData);

  const api_url =
    "https://api.airtable.com/v0/app6ordLul6mK6lEv/Table%201?api_key=keyTzNFoKWiWzSmPa&sort%5B0%5D%5Bfield%5D=order";

  useEffect(() => {
    let width = window.innerWidth;
    if (width > 900) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }

    fetch(api_url)
      .then((res) => res.json())
      .then((data) => {
        setArtworkData(data.records);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="home-main">
      {desktop ? (
        artworkData && <Carousel artworkData={artworkData} />
      ) : (
        <MobileCarousel artworkData={artworkData} />
      )}

      {desktop && (
        <div className="home-static">
          <p>
            Scroll through a selection of some of our favorite artworks we've
            exhibited in our first year.
          </p>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-arrows-down-up"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#2c3e50"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="17" y1="3" x2="17" y2="21" />
            <path d="M10 18l-3 3l-3 -3" />
            <line x1="7" y1="21" x2="7" y2="3" />
            <path d="M20 6l-3 -3l-3 3" />
          </svg> */}
        </div>
      )}
      <Socials />
    </div>
  );
}
