import React, { useEffect, useState } from "react";
import logo from "./media/bubble_logo_thick.png";
import Socials from "./Socials";
import fwbLogo from "./media/FWB.png";

const biopt1 =
  "For The TL is a cryptoart collective consisting of over 50 visual artists creating NFTs, founded by artist and curator Max Kulchinsky, alongside founding members Elijah Kuhaupt and Squibs.";
const biopt2 =
  "With the goal of representing the outstanding talent pool in cryptoart, this group is vastly diverse in artistic style, as well as race, gender, location, and beyond. These incredibly talented artists range from animators to 3D artists, illustrators, painters, and everything in between.";
const biopt3 =
  "Since forming around 2021 Art Basel Miami, For The TL has produced numerous IRL cryptoart exhibitions, dropped its first collection of NFTs on Nifty Gateway during NFT NYC 2022, been featured on publications including ONE37pm (Gary Vee), and received grants from crypto organizations including FWB (Friends With Benefits) to scale its efforts of artist support and community.";

export default function About() {
  const api_url =
    "https://api.airtable.com/v0/appGeBK7Y1amKrLUa/Table%201?api_key=keyTzNFoKWiWzSmPa";
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(api_url)
      .then((res) => res.json())
      .then((data) => {
        setData(data.records);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [api_url]);

  const pressElement = data.map((data) => (
    <div>
      <div className="press-entry">
        <a target="_blank" rel="noopener noreferrer" href={data.fields.link}>
          {data.fields.title}
        </a>

        <div className="press-details">
          <p>{data.fields.date}</p>
          <p>{data.fields.publication}</p>
        </div>
      </div>

      <hr></hr>
    </div>
  ));

  return (
    <div className="about-main">
      <h1>about</h1>
      <div className="hr">
        <br></br>
      </div>

      <div className="about-body">
        <div className="about-container">
          <h3>{biopt1}</h3>
          <br></br>
          <p>{biopt2}</p>
          <br></br>
          <p>{biopt3}</p>
          <img className="about-logo" alt="logo" src={logo}></img>
        </div>

        <div className="press-container">
          <h2>Press</h2>
          {pressElement}
        </div>
      </div>

      <div className="attributions-container">
        <p>
          This website would not have been possible without the incredible
          design and development of{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/alyssajoines"
          >
            Alyssa Joines
          </a>
          , and support from the{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.fwb.help/"
          >
            Friends With Benefits
          </a>{" "}
          Garage Grant.
        </p>
        <img height="20" alt="fwb logo" className="fwb-img" src={fwbLogo}></img>
        <br></br>
        <h3>Illustrations:</h3>
        <p>
          Twitter icon:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/bawbicai"
          >
            Bobbi Cai
          </a>
        </p>
        <p>
          Email icon:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/vinniehager"
          >
            Vinnie Hager
          </a>
        </p>
      </div>

      <Socials />
    </div>
  );
}
