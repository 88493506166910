import React, { useState } from "react";

export default function Card(props) {
  const { x, xOffset, height, width, windowWidth, shouldLoad } = props;

  const [isLoaded, setIsLoaded] = useState(false);

  // [ ] [ ] [ ] [ ] [ ] [ ]
  // determine the x offset of this card in the list
  // determine the offset relative to the window based on

  const margin = windowWidth * 2;
  const windowStart = -xOffset;
  const windowEnd = -xOffset + windowWidth;

  const inWindow = (point) => {
    return point > windowStart - margin && point < windowEnd + margin;
  };

  const inFocus = inWindow(x) || inWindow(x + width);

  const cardStyle = {
    position: "absolute",
    width: width + "px",
    height: height + "px",

    transform: `translate3d(${xOffset}px, 0, 1px)`,
    left: x + "px",
    top: "0",
  };

  // console.log({ cardStyle });

  return (
    <div className="card" style={cardStyle}>
      {inFocus && shouldLoad && (
        <>
          {props.img ? (
            <a target="_blank" rel="noopener noreferrer" href={props.link}>
              <img
                className="carousel-img"
                src={props.img}
                alt="artwork"
                loading="lazy"
                onLoad={(e) => {
                  props.imageLoad(
                    props.index,
                    e.target.naturalHeight / e.target.naturalWidth
                  );
                  setIsLoaded(true);
                }}
                data-loaded={isLoaded}
              ></img>
            </a>
          ) : (
            <a target="_blank" rel="noopener noreferrer" href={props.link}>
              <img
                className="carousel-img"
                src={props.thumb}
                alt="artwork"
                loading="lazy"
                onLoad={(e) => {
                  props.imageLoad(
                    props.index,
                    e.target.naturalHeight / e.target.naturalWidth
                  );
                  setIsLoaded(true);
                }}
                data-loaded={isLoaded}
              ></img>
            </a>
          )}

          {!isLoaded && <span>Loading...</span>}

          <div className="card-info">
            <ul>
              <li>
                <i>{props.title}</i>
              </li>
              <li>{props.artist}</li>
              <li>{props.show}</li>
              {props.vid && (
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={props.link}
                  >
                    view video artwork
                  </a>
                </li>
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
