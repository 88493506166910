import React, { useEffect, useState } from "react";
import Exhibition from "./Exhibition";
import Socials from "./Socials";

const sort = "&sort%5B0%5D%5Bfield%5D=chrono&sort%5B0%5D%5Bdirection%5D=desc";

export default function Archive() {
  const [exhibit, setExhibits] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.airtable.com/v0/appMAKxM1GMg3tfBS/Table%201?api_key=keyTzNFoKWiWzSmPa${sort}`
    )
      .then((res) => res.json())
      .then((data) => {
        setExhibits(data.records);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const exhibitionInfo = exhibit.map((data) => (
    <div className="project-name">
      <Exhibition
        key={data.id}
        name={data.fields.name}
        location={data.fields.location}
        date={data.fields.date}
        description={data.fields.description}
        link={data.fields.link}
        imgUrl={data.fields.img_url1}
        imgUrl2={data.fields.img_url2}
        archive_id={data.fields.art_db_id}
      />
    </div>
  ));

  return (
    <div className="archive-main">
      <h1>previous exhibitions</h1>
      <div className="hr">
        <br></br>
      </div>

      <div className="archive-container">{exhibitionInfo}</div>

      <Socials />
    </div>
  );
}
