import React, { useCallback, useEffect, useMemo, useState } from "react";
import Card from "./Card";
import { useWindowSize } from "../Hooks/useWindowSize";

const padding = 80;

export default function Carousel(props) {
  // window dimensions
  const size = useWindowSize();

  // xOffset
  const [xOffset, setxOffset] = useState(0);

  // artwork dimension map
  const [artwork, setArtwork] = useState(
    props.artworkData.map((artwork) => ({ ...artwork, aspectRatio: 1 }))
  );

  const artworkLayout = useMemo(() => {
    const layouts = [];

    let x = 0;

    artwork.forEach((artwork) => {
      const cardHeight = size.height * 0.6;
      const cardWidth = cardHeight / artwork.aspectRatio;
      layouts.push({
        height: cardHeight,
        width: cardWidth,
        x,
        artwork,
      });

      x += cardWidth + padding;
    });

    return layouts;
  }, [artwork, size]);

  const FLOOR = -3700;
  const CEILING = 700;

  /**
   * useEffect that adds a listener for wheel that updates the
   * xOffset and uses that to translate the content left and right
   */

  useEffect(() => {
    const updateMousePosition = (ev) => {
      ev.preventDefault();
      setxOffset((prevState) =>
        Math.min(Math.max(FLOOR, prevState + ev.deltaY), CEILING)
      );
    };

    window.addEventListener("wheel", updateMousePosition, { passive: false });

    return () => {
      window.removeEventListener("wheel", updateMousePosition);
    };
  }, []);

  const onImageLoad = useCallback((cardIndex, aspectRatio) => {
    setArtwork((prevState) => {
      // console.log({ cardIndex, aspectRatio });
      const newState = [...prevState];
      newState[cardIndex].aspectRatio = aspectRatio;
      newState[cardIndex].isLoaded = true;
      return newState;
    });
  }, []);

  // console.log({ artwork, artworkLayout });

  const card = artworkLayout.map((data, index) => {
    const isFirstCard = index === 0;
    const isLastCardLoaded =
      !isFirstCard &&
      (artwork[index - 1].isLoaded || artwork[index - 1].fields.vid_thumbnail);

    return (
      <div>
        <Card
          index={index}
          imageLoad={onImageLoad}
          x={data.x}
          xOffset={xOffset}
          height={data.height}
          width={data.width}
          windowWidth={size.width}
          title={data.artwork.fields.title}
          artist={data.artwork.fields.artist}
          medium={data.artwork.fields.medium}
          date={data.artwork.fields.year}
          show={data.artwork.fields.event}
          img={data.artwork.fields.img_url}
          vid={data.artwork.fields.video_url}
          thumb={data.artwork.fields.vid_thumbnail}
          link={data.artwork.fields.link_out}
          id={data.artwork.fields.id}
          key={`card-${index}`}
          shouldLoad={isFirstCard || isLastCardLoaded}
        />
      </div>
    );
  });

  return <div className="carousel-container">{card}</div>;
}
