import React, { useEffect, useState, useRef } from "react";
import ArtRecord from "./ArtworkArch";
import bubbleLogo from "./media/bubble_logo_thick.png";

const sort = "&sort%5B0%5D%5Bfield%5D=screen_num";

export default function Exhibition(props) {
  //api state and url
  const [artwork, setArtwork] = useState([]);
  const api_url = `https://api.airtable.com/v0/appYQe8VsCF7XbYed/${props.archive_id}?api_key=keyTzNFoKWiWzSmPa${sort}`;

  useEffect(() => {
    fetch(api_url)
      .then((res) => res.json())
      .then((data) => {
        setArtwork(data.records);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [api_url]);

  //collapsible state, function, animation
  const [open, setOpen] = useState(false);

  const contentRef = useRef();
  if (contentRef.current) console.log(contentRef.current.scrollHeight);

  function toggleDropdown() {
    setOpen((PrevState) => !PrevState);
  }

  //artworks
  const artworkElement = artwork.map((data) => (
    <div className="project-name">
      <ArtRecord
        title={data.fields.title}
        artist={data.fields.artist}
        medium={data.fields.medium}
        date={data.fields.year}
        link={data.fields.link_out}
        img={data.fields.art_img}
        vid={data.fields.art_vid}
        thumb={data.fields.vid_thumbnail}
        key={data.id}
      />
    </div>
  ));

  return (
    <div className="exhibition-container">
      <div className="exhibition-title">
        <h3 className="blue">{props.name}</h3>

        <div className="detail-align">
          {!props.link && <h3>{props.location}</h3>}
          {props.link && (
            <a href={props.link}>
              <h3>{props.location}</h3>
            </a>
          )}
          <p>{props.date}</p>
        </div>
      </div>

      <hr></hr>

      <div className="exhibition-details">
        <div className="exhibition-images">
          <img
            className="event-img"
            alt="exhibit"
            load="lazy"
            src={props.imgUrl}
          ></img>
          {props.imgUrl2 && (
            <img
              className="event-img"
              alt="exhibit"
              load="lazy"
              src={props.imgUrl2}
            ></img>
          )}
        </div>

        <div className="exhibition-text">
          <p>{props.description}</p>
        </div>
      </div>

      <hr></hr>

      <div className="expand">
        <button onClick={toggleDropdown}>Artworks</button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-arrows-up-down"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#2c3e50"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="7" y1="3" x2="7" y2="21" />
          <path d="M10 6l-3 -3l-3 3" />
          <path d="M20 18l-3 3l-3 -3" />
          <line x1="17" y1="21" x2="17" y2="3" />
        </svg>
      </div>

      <div>
        {open && (
          <>
            {artworkElement}
            <hr className="dashed"></hr>
            <div>
              <img className="dropdown-logo" alt="logo" src={bubbleLogo}></img>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
