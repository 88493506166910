import React from "react";
import Home from "./Components/Home";
import Archive from "./Components/Archive";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./Components/About";
import NavbarDesktop from "./Components/NavbarDesktop";
import NavbarMobile from "./Components/NavbarMobile";

function App() {
  let width = window.innerWidth;
  if (width > 900) {
    return (
      <div>
        <NavbarDesktop />
        <Routes>
          <Route element={<Home />} exact path="/" />
          <Route element={<About />} path="/about" />
          <Route element={<Archive />} path="/archive" />
        </Routes>
      </div>
    );
  } else {
    return (
      <div>
        <NavbarMobile />
        <Routes>
          <Route element={<Home />} exact path="/" />
          <Route element={<About />} path="/about" />
          <Route element={<Archive />} path="/archive" />
        </Routes>
      </div>
    );
  }
}

export default App;
