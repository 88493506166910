import React from "react";
import twtLogo from "./media/twitter-icon.png";
import letter from "./media/letter.png";

export default function Socials() {
  return (
    <div className="socials-right">
      <ul>
        <li>
          <a
            className="nav-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/forthetl"
          >
            <img src={twtLogo} alt="twitter icon" className="twitter"></img>
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:forthetlart@gmail.com"
          >
            <img src={letter} alt="twitter icon" className="email"></img>
          </a>
        </li>
      </ul>
    </div>
  );
}
